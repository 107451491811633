import { ZDTAds, ZDTProduct, ZDTCatalog, ZDTCategory } from '@zalora/doraemon-ts';
import * as sellerApi from 'api/seller';
import { FilterName } from 'constants/catalog';
import { CatalogType, PageInfo } from 'utils/catalog/catalog-page-type';
import { getFilterByType } from 'utils/catalog/filters';
import { concatABtestParam, getCatalogParams } from 'utils/catalog/query';
import { getDeviceId } from 'utils/device-id';
import { PAGE_TYPE_REGEX, isPageType } from 'utils/page-type';
import { HEADERS, QueryParams, simpleFetch } from './APIClient';
import { ApiError } from './apiError';

export enum ProductListingApi {
  filter = 'filter',
  list = 'list',
}

export const getCategories = async (parentID: string) => {
  const res = await simpleFetch<ZDTCategory.CategoryList>(`/v1/catalog/categories/`, {
    params: {
      shop: 'm',
      returnAll: false,
      parentID,
    },
  });

  return res.data?.Categories;
};

export const getFilterOptions = async ({
  categoryId,
  subCategoryId,
  query,
  filterType,
}: {
  categoryId?: string;
  subCategoryId?: string;
  query: QueryParams;
  filterType: FilterName | string | undefined;
}) => {
  const info = {
    catalogType: isPageType(PAGE_TYPE_REGEX.SEARCH_PAGE) ? CatalogType.SEARCH : CatalogType.CATALOG,
  } as PageInfo;

  if (categoryId) {
    info.categoryId = categoryId;
  }

  if (subCategoryId) {
    info.categoryId = subCategoryId;
  }

  const params = getCatalogParams(query, info);

  const res = await simpleFetch<ZDTCatalog.FilterResponse>(
    `/v1/products/${ProductListingApi.filter}/`,
    { params },
  );

  const filter = res.data ? getFilterByType(res.data, filterType) : undefined;
  const filterOptions = filter?.Options || [];

  return filterOptions;
};

export const getSponsoredProducts = async (
  params: QueryParams,
): Promise<ZDTProduct.ProductList | undefined> => {
  const deviceId = getDeviceId() || '';

  const res = await simpleFetch<ZDTProduct.ProductList>(`/v1/products/sponsored/`, {
    credentials: 'include',
    params: {
      ...params,
      deviceId,
    },
  });

  return res.data;
};

export const getProductList: {
  (api: ProductListingApi.filter, params: QueryParams): Promise<ZDTCatalog.FilterResponse>;
  (api: ProductListingApi.list, params: QueryParams): Promise<ZDTCatalog.ProductResponse>;
} = async <T>(api: ProductListingApi, params: QueryParams) => {
  const res = await simpleFetch<T>(`/v1/products/${api}/`, {
    params,
  });

  if (res.status !== 200) {
    throw new ApiError({
      message: `Errors occured on API /v1/products/${api}`,
      status: res.status,
      url: `/v1/products/${api}`,
      data: { params },
    });
  }

  return res.data;
};

export const getCatalogBanner = async (params: QueryParams, locale: string) => {
  const res = await simpleFetch<ZDTAds.Ads>(`/v1/ads`, {
    params,
    headers: {
      [HEADERS.CONTENT_LANG]: locale,
    },
    credentials: 'include',
  });

  return res.data;
};

export const getProductZISListWrapper = (sellerId: string) => {
  const returnedFunction = async (api: ProductListingApi, params: QueryParams) => {
    let res;

    switch (api) {
      case ProductListingApi.filter: {
        res = await sellerApi.callById(sellerApi.SellerIdEndpoint.getFilterFields, sellerId, {
          params,
        });
        break;
      }

      case ProductListingApi.list: {
        res = await sellerApi.callById(sellerApi.SellerIdEndpoint.getProducts, sellerId, {
          params,
        });
        break;
      }

      default: {
        res = null;
        break;
      }
    }

    if (res?.status !== 200) {
      throw new ApiError({
        message: `Errors occured while fetching API ${api} from getProductZISListWrapper`,
        status: res?.status,
        url: api,
        data: { params },
      });
    }

    return res?.data;
  };

  return returnedFunction as {
    (api: ProductListingApi.filter, params: QueryParams): Promise<ZDTCatalog.FilterResponse>;
    (api: ProductListingApi.list, params: QueryParams): Promise<ZDTCatalog.ProductResponse>;
  };
};

export const getProductZISListWrapperFromDatajet = (sellerId: string) => {
  const returnedFunction = async (api: ProductListingApi, params: QueryParams) => {
    let res;

    switch (api) {
      case ProductListingApi.filter: {
        res = await simpleFetch(`/v1/dynseller/${sellerId}/datajet/products/filter`, {
          params,
        });
        break;
      }

      // it's `ProductListingApi.list` case
      default: {
        res = await simpleFetch(`/v1/dynseller/${sellerId}/datajet/products/list`, {
          params,
        });

        break;
      }
    }

    if (res?.status !== 200) {
      throw new ApiError({
        message: `Errors occured while fetching API ${api} from getProductZISListWrapperFromDatajet`,
        status: res?.status,
        url: api,
        data: { params },
      });
    }

    return res?.data;
  };

  return returnedFunction as {
    (api: ProductListingApi.filter, params: QueryParams): Promise<ZDTCatalog.FilterResponse>;
    (api: ProductListingApi.list, params: QueryParams): Promise<ZDTCatalog.ProductResponse>;
  };
};

export const getProductListDatajet = (abTestParam?: string) => {
  const returnedFunction = async <T>(api: ProductListingApi, params: QueryParams) => {
    const requestParams = abTestParam
      ? {
          ...params,
          abtest: concatABtestParam(abTestParam, params.abtest as string | string[]),
        }
      : params;
    const res = await simpleFetch<T>(`/v1/dynproducts/datajet/${api}`, {
      params: requestParams,
    });

    if (res.status !== 200) {
      throw new ApiError({
        message: `Errors occured on API /v1/dynproducts/datajet/${api}`,
        status: res.status,
        url: `/v1/dynproducts/datajet/${api}`,
        data: { params: requestParams },
      });
    }

    return res.data;
  };

  return returnedFunction as {
    (api: ProductListingApi.filter, params: QueryParams): Promise<ZDTCatalog.FilterResponse>;
    (api: ProductListingApi.list, params: QueryParams): Promise<ZDTCatalog.ProductResponse>;
  };
};

export const getCollection = async (params: QueryParams) => {
  const res = await simpleFetch<{ Collections: ZDTProduct.Collection[] }>(
    `/v1/catalog/attributes/collection/`,
    {
      params,
    },
  );

  return res.data;
};
